
import { Vue, Component } from "vue-property-decorator";
import Room from "@/components/task/assignment/components/Room.vue";
import DemoRoom from "@/components/task/assignment/components/DemoRoom.vue";
import Student from "@/components/task/assignment/components/Student.vue";
import DemoStudent from "@/components/task/assignment/components/DemoStudent.vue";

@Component({
  components: { DemoStudent, Student, DemoRoom, Room },
})
export default class ConstraintsDetail extends Vue {
  subjects = ["A", "B", "C", "D"];
  rooms = ["001", "002"];
  assignment = {
    "001": ["A", "C", "D"],
    "002": ["B"],
  };
}
