
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";

@Component({
  name: "Student",
  computed: {
    ...mapState("user", ["userId"]),
  },
  methods: {
    ...mapMutations("roomAssignment", {
      removeFromRoom: "REMOVE_SUBJECT_FROM_ROOM",
    }),
  },
})
export default class Student extends Vue {
  @Prop({ required: true, type: String })
  student!: string;
  @Prop({ required: false, type: String })
  player: string | undefined = undefined;
  @Prop({ required: true })
  room!: number | string;
  @Prop({ required: true })
  index!: number | string;
  @Prop({ type: Function, required: true })
  eventHandler!: CallableFunction;

  stage: Record<string, any> = {};
  userId: string | undefined;
  isDraggable = true; // usually everyone can drag, except if it is colored (i.e., being dragged by someone else)
  dragger: string | undefined;
  isDragged = false;

  created(): void {
    this.dragger = this.stage[`student-${this.student}-dragger`];
  }

  handleDragStart(event: DragEvent): void {
    // Check if there's already a dragger
    this.dragger = this.stage[`student-${this.student}-dragger`];
    if (this.dragger) {
      //  If already dragged, you can't move it
      event.preventDefault();
      return;
    }
    // TODO: set as computed property
    this.dragger ||= this.userId;
    this.isDragged = true;
    Vue.set(this.stage, `student-${this.student}-dragger`, this.userId);
    // Spread to other client that someone took this student
    // this.eventHandler({
    //   user_id: this.userId,
    //   type: "drag",
    //   object: this.student,
    //   target: "",
    //   source: this.room,
    // });
    event.dataTransfer?.setData(
      "application/json",
      JSON.stringify({
        source: this.room,
        subject: this.student,
        source_index: this.index,
      })
    );
  }
  handleDragOver(event: DragEvent): void {
    event.preventDefault();
  }
  handleDragEnd(event: DragEvent): void {
    this.isDragged = false;
    this.stage[`student-${this.student}-dragger`] = null;
    this.dragger = undefined;

    if (event.dataTransfer?.dropEffect === "none") {
      // if dropped into non-allowed area
      this.stage.log.append({
        verb: "releasedStudent",
        subjectId: this.userId,
        object: this.student,
      });
      return;
    }
    this.removeFromRoom({
      room: this.room,
      subject: this.student,
      index: this.index,
    });
  }
}
