
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "LaunchingPage",
  emits: ["restart"],
})
export default class LaunchingPage extends Vue {
  redirectToActivity(): void {
    this.$router.push({
      name: "assignmentSingleSession",
      params: { sessionId: this.$route.params["sessionId"] },
    });
  }
}
