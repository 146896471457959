
import { Vue, Component, Prop } from "vue-property-decorator";
import { EventType } from "@/schemas/Enums";

@Component({
  name: "PageRenderer",
})
export default class PageRenderer extends Vue {
  @Prop({ type: Array, required: true })
  components: Vue.Component[];

  currentIndex = 0;

  created(): void {
    document.onkeydown = this.onKeyEvent;
  }

  get currentComponent(): Vue.Component {
    return this.components[this.currentIndex];
  }

  goForward(): void {
    if (this.currentIndex < this.components.length - 1) {
      this.currentIndex++;
    }
  }

  goBackward(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  restart(): void {
    this.currentIndex = 0;
  }

  onKeyEvent(keyboardEvent: KeyboardEvent): void {
    switch (keyboardEvent.key) {
      case "ArrowLeft":
        this.goBackward();
        break;

      case "ArrowUp":
        this.goForward();
        break;

      case "ArrowRight":
        this.goForward();
        break;

      case "ArrowDown":
        this.goBackward();
        break;

      default:
        return;
    }
    keyboardEvent.preventDefault(); // prevent the default action (scroll / move caret)
  }
}
