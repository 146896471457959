
import { Vue, Component, Prop } from "vue-property-decorator";
import PageRenderer from "@/components/PageRenderer.vue";
import RoomsArrangementDetail from "@/views/task/assignment/intro/RoomsArrangementDetailPage.vue";
import ScoreDetailPage from "@/views/task/assignment/intro/ScoreDetailPage.vue";
import PayoffDetail1Page from "@/views/task/assignment/intro/PayoffDetail1Page.vue";
import PayoffDetail2Page from "@/views/task/assignment/intro/PayoffDetail2Page.vue";
import ConstraintsDetail from "@/views/task/assignment/intro/ConstraintsDetailPage.vue";
import ConcreteExamplePage from "@/views/task/assignment/intro/ConcreteExamplePage.vue";
import LaunchingPage from "@/views/task/assignment/intro/LaunchingPage.vue";

@Component({
  components: { PageRenderer },
})
export default class IntroPage extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;

  components = [
    RoomsArrangementDetail,
    ScoreDetailPage,
    PayoffDetail1Page,
    PayoffDetail2Page,
    ConstraintsDetail,
    ConcreteExamplePage,
    LaunchingPage,
  ];

  read = false;
}
