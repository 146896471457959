
import { Vue, Component } from "vue-property-decorator";
import Room from "@/components/task/assignment/components/Room.vue";
import DemoStudent from "@/components/task/assignment/components/DemoStudent.vue";
import DemoRoom from "@/components/task/assignment/components/DemoRoom.vue";
import { assetsUrl } from "@/env";

@Component({
  components: { DemoRoom, DemoStudent, Room },
})
export default class RoomsArrangementDetail extends Vue {
  getImgSrc(filename: string): string {
    return `${assetsUrl}/activities/assignment/${filename}`;
  }
}
