
import { UFliterals } from "@/utils/literals";
import { Component, Prop, Vue } from "vue-property-decorator";
import Student from "@/components/task/assignment/components/Student.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

@Component({
  name: "Room",
  components: { Student },
  // emits: ["objectDropped"],
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("roomAssignment", {
      deckSubjects: "subjects",
      assignment: "assignment",
    }),
    ...mapGetters("roomAssignment", ["getRoomAssignmentById"]),
  },
  methods: {
    ...mapMutations("roomAssignment", {
      assignSubjectToRoom: "ASSIGN_SUBJECT_TO_ROOM",
      setAssignedSubjects: "SET_ASSIGNED_SUBJECTS",
      addSubjectToDeck: "ADD_SUBJECT_TO_DECK",
    }),
  },
})
export default class Room extends Vue {
  @Prop({ type: Number | String, required: true })
  room!: number | string;
  @Prop({ required: false, type: Boolean, default: false })
  isDeck: boolean;
  @Prop({ type: Function, required: true })
  eventHandler!: CallableFunction;

  stage: Record<string, any> = {};
  userId: string | undefined;
  hovered = false;

  get subjects(): string[] {
    return this.assignment[this.room];
  }

  set subjects(subject: string): void {
    this.assignSubjectToRoom({ room: this.room, subject });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  handleDrop(event: DragEvent): void {
    let eventData = JSON.parse(event.dataTransfer.getData("application/json"));
    this.subjects = eventData.subject;
    this.stage[`subject-${eventData.subject}-dragger`] = null;
    this.hovered = false;
    let currentRoom = this.stage[`subject-${eventData.subject}-room`];
    // Avoid any unwanted drops!
    // We're using the native DnD system, which mean people can drag anything
    // onto these drop zones (e.g. files from their desktop) so we check this
    // is an existing subject first.
    if (currentRoom === this.room) {
      // if they kept the subject where it is, log that they stayed in the same place And don't change the answer
      // this.stage.log.append({
      //   verb: "releasedSubject",
      //   subjectId: this.userId,
      //   object: eventData.subject,
      // });
      // Released and unlock subject
      // this.eventHandler({
      //   user_id: this.userId,
      //   type: "released_subject",
      //   object: eventData.subject,
      //   target: this.room,
      //   source: eventData.source,
      // });
      return;
    }
    this.stage[`subject-${eventData.subject}-room`] = this.room;

    this.eventHandler({
      user_id: this.userId,
      type: "drop",
      object: eventData.subject,
      target: this.room,
      source: eventData.source,
    });

    // this.stage.log.append({
    //   verb: "movedSubject",
    //   subjectId: this.userId,
    //   object: eventData.subject,
    //   target: this.room,
    //   at: new Date(),
    // });

    // this.$emit("objectDropped");
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    this.hovered = true;
  }

  handleDragLeave(event: DragEvent): void {
    this.hovered = false;
  }
}
