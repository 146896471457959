
import { UFliterals } from "@/utils/literals";
import { Component, Prop, Vue } from "vue-property-decorator";
import Student from "@/components/task/assignment/components/Student.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

@Component({
  name: "DemoRoom",
  components: { Student },
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("roomAssignment", {
      deckSubjects: "subjects",
      assignment: "assignment",
    }),
    ...mapGetters("roomAssignment", ["getRoomAssignmentById"]),
  },
  methods: {
    ...mapMutations("roomAssignment", {
      assignSubjectToRoom: "ASSIGN_SUBJECT_TO_ROOM",
      setAssignedSubjects: "SET_ASSIGNED_SUBJECTS",
      addSubjectToDeck: "ADD_SUBJECT_TO_DECK",
    }),
  },
})
export default class DemoRoom extends Vue {
  @Prop({ type: Number | String, required: true })
  room!: number | string;
  @Prop({ required: false, type: Boolean, default: false })
  isDeck: boolean;

  stage: Record<string, any> = {};
  userId: string | undefined;
  hovered = false;

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
