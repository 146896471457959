
import { Vue, Component } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";

@Component({})
export default class PayoffDetail2Page extends Vue {
  payoff = {
    A: {
      "001": 24,
      "002": 78,
    },
    B: {
      "001": 51,
      "002": 54,
    },
    C: {
      "001": 94,
      "002": 47,
    },
    D: {
      "001": 60,
      "002": 56,
    },
  };
  subjects = ["A", "B", "C", "D"];
  rooms = ["001", "002"];

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
