
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DemoStudent",
})
export default class DemoStudent extends Vue {
  @Prop({ required: true, type: String })
  student!: string;
  @Prop({ required: true })
  index!: number | string;

  isDragged = false;
}
